<template>
  <div>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      background-color="#fff"
      :is-full-page="false">
    </loading>
    <b-col 
      lg="8" offset-lg="2" 
      v-if="!loading">                        
        <b-card bg-variant="light" header="Resultado de la operación">
          <b-row>
            <b-col lg="6" class="text-right">
                <strong>Nombre de la empresa:</strong>
            </b-col>
            <b-col lg="6">
                {{company}}
            </b-col>
            
            <!-- <b-col lg="6" class="text-right">
                <strong>NIT:</strong>
            </b-col>
            <b-col lg="6">
                
            </b-col> -->

            <b-col lg="6" class="text-right">
                <strong>Fecha transacción:</strong>
            </b-col>
            <b-col lg="6">
                {{date}}
            </b-col>
            <b-col lg="6" class="text-right">
                <strong>Estado:</strong>
            </b-col>
            <b-col lg="6">
                <div v-if="flagFinish">
                        <b-badge class="m-1" pill variant="success">{{state}}</b-badge>
                </div>
                <div v-if="flagRetry">
                    <b-badge class="m-1" pill variant="warning">{{state}}</b-badge>
                </div>
                
            </b-col>

            <b-col lg="6" class="text-right">
                <strong>Referencia de pedido:</strong>
            </b-col>
            <b-col lg="6">
                {{referenceCode}}
            </b-col>

            <b-col lg="6" class="text-right">
                <strong>Referencia transacción:</strong>
            </b-col>
            <b-col lg="6">
                {{transactionId}}
            </b-col>
            
            <b-col lg="6" class="text-right">
                <strong>Número de transacción/CUS:</strong>
            </b-col>
            <b-col lg="6">
                {{cus}}
            </b-col>

            <b-col lg="6" class="text-right">
                <strong>Banco:</strong>
            </b-col>
            <b-col lg="6">
                {{pseBank}}
            </b-col>

            <b-col lg="6" class="text-right">
                <strong>Valor:</strong>
            </b-col>
            <b-col lg="6">
                {{TX_VALUE}}
            </b-col>

            <b-col lg="6" class="text-right">
                <strong>Moneda:</strong>
            </b-col>
            <b-col lg="6">
                {{currency}}
            </b-col>

            <b-col lg="6" class="text-right">
                <strong>Descripción:</strong>
            </b-col>
            <b-col lg="6">
                {{description}}
            </b-col>

            <b-col lg="6" class="text-right">
                <strong>IP origen:</strong>
            </b-col>
            <b-col lg="6">
                {{pseReference1}}
            </b-col>
                      

            <b-col lg="12">
                <hr>
            </b-col>

            <!-- Buttons -->

            <b-col lg="4" offset-lg="2" class="btn-sm text-center" v-if="flagRetry">
                <b-button class="primary text-gray" @click="fetchState">
                    Reintentar consulta
                </b-button>
            </b-col>
            <b-col lg="4" offset-lg="2" class="btn-sm text-center" v-if="flagFinish">
                <b-button class="primary text-gray" @click="showPurchase">
                    Finalizar transacción
                </b-button>
            </b-col>
            <b-col lg="4" class="btn-sm text-center">
                <b-button @click="exportPDF">
                    Descargar PDF
                </b-button>
            </b-col>
          </b-row>                                                    
        </b-card>                
    </b-col> 
  </div> 
</template>

<script>
import moment from 'moment';
import Loading from 'vue-loading-overlay';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
export default {
  name: "payment_confirmation",
  components: {
    Loading,    
  },
  data () {
    return {
      loading: true,
      company: 'Go Guau',
      date: null,
      state: null,
      polTransactionState: null,
      polResponseCode: null,
      referenceCode: null,
      transactionId: null,
      cus: null,
      pseBank: null,
      TX_VALUE: null,
      currency: null,
      description: null,
      pseReference1: null,
      flagRetry: false,
      flagFinish: false,
      serviceId: null,
      flagCard: false,
    }
  },
  created() {
    // this.loading = false
    if(this.$route.query != null){
    //   console.log(this.$route.query)
      let data = this.$route.query
      if(data.debitCard == "true"){
        this.flagCard = false
        this.transactionId = data.transactionId
        this.referenceCode = data.referenceCode
        this.validateOrder(this.referenceCode)
      }else{
        this.setInfo(data)
      }                               
    }
  },
  methods: {
    setInfo (data) {
        this.referenceCode = data.referenceCode
        this.transactionId = data.transactionId
        this.cus = data.cus
        this.pseBank = data.pseBank
        this.TX_VALUE = data.TX_VALUE 
        this.currency = data.currency
        this.description = data.description
        this.pseReference1 = data.pseReference1
        this.polTransactionState = data.polTransactionState
        this.polResponseCode = data.polResponseCode            

        if( this.polTransactionState ==  '4' && this.polResponseCode == '1'){
            this.validateOrder(this.referenceCode)
            this.state = 'Transacción aprobada'                
        }
        else if (this.polTransactionState ==  '6') {
            if (this.polResponseCode == '5') {
                this.state = 'Transacción fallida'
                this.flagRetry = true
                this.loading = false
            }
            else if (this.polResponseCode == '4') {
                this.state = 'Transacción rechazada'
                this.flagRetry = true
                this.loading = false
            }
        }
        else if (this.polTransactionState ==  '12' || this.polTransactionState ==  '14') {
            if (this.polResponseCode == '9994' || this.polResponseCode == '25') {
                this.state = 'Transacción pendiente, por favor revisar si el débito fue realizado en el banco.'
                this.flagRetry = true
                this.loading = false
            }
        }
    },
    validateOrder(referenceCode){            
        let serviceId = referenceCode.split('orderId-')[1]
        let servicesIds = serviceId.split(',')
        let payload = {                
            transactionId: this.transactionId,
            serviceId: servicesIds
        }
        // console.log('payload')
        // console.log(payload)     
        let getTransactionStatus = this.$firebase.functions().httpsCallable('getTransactionStatus')
        getTransactionStatus(payload).then((res) => {    
            // console.log(res.data.data)       
                            
            let response = res.data.data.result //PSE info                
            
            let code = res.data.data.code                
            let payload = response.payload
            let state = payload.state  
            
            // let service = res.data.services //Service info
            // console.log('code:', code)
            // console.log('state',state)
            if (code == 'SUCCESS') {
                this.date = moment(payload.creationDate).format('dddd DD/MMMM/YYYY hh:mm a')                 
                if (state == 'APPROVED'){
                    this.serviceId = referenceCode.split('orderId-')[1]
                    this.flagFinish = true
                    this.state = 'Transacción aprobada'
                }  else if (state == 'PENDING'){
                  this.state = 'Transacción pendiente'
                  this.flagRetry = true
                }  else if (state == 'DECLINED') {  
                  this.state = 'Transacción rechazada'
                  this.flagRetry = true
                }                    
            } else {                
                if(state == 'ERROR'){
                  this.state = 'Error en la transacción'
                }else if(state == 'EXPIRED'){
                  this.state = 'La transacción ha espirado'
                }else if(state == 'PENDING'){
                  this.state = 'Transacción pendiente'
                }else if (state == 'DECLINED') {                    
                  this.state = 'Transacción rechazada'
                }

                this.flagRetry = true                                        
                //ERROR, EXPIRED, PENDING, SUBMITTED
            }                
        }).catch((e) => {
            console.log('Error')
            console.log(e)
        }).finally(()=>{
            this.loading = false
        })
    },
    exportPDF() {            
        var columns = [
            {title: "Concepto", dataKey: "concept"},
            {title: "Descripción", dataKey: "description"}
        ];
        var doc = new jsPDF('p', 'pt');   
        
        let todos= [
            {concept: 'Empresa', description: this.company},
            {concept: 'Fecha transacción', description: this.date},
            {concept: 'Estado', description: this.state},
            {concept: 'Referencia de pedido', description: this.referenceCode},
            {concept: 'Referencia de transacción', description: this.transactionId},
            {concept: 'Número de transacción/CUS', description: this.cus},
            {concept: 'Banco', description: this.pseBank},
            {concept: 'Valor', description: this.TX_VALUE},
            {concept: 'Moneda', description: this.currency},
            {concept: 'Descripción', description: this.description},
            {concept: 'IP origen', description: this.pseReference1},
        ]
                
        doc.text('Comprante de transacción', 40, 40);
        doc.autoTable(columns, todos, {
            margin: {top: 60},
        });
        let serviceId = this.referenceCode.split('orderId-')[1]
        doc.save('Recibo-'+serviceId+'.pdf');            
    },
    fetchState () {
      this.loading = true
      this.validateOrder(this.referenceCode)
    }
    
  },

}
</script>

<style scoped>
</style>